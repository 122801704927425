import React, { useEffect, useState } from 'react'
import PageBreadcrumbs from 'components/commons/PageBreadcrumbs'
import { ReactComponent as MinistryIcon } from 'assets/icons/agreement.svg'
import { Helmet } from 'react-helmet'
import MinistryForm from 'components/forms/MinistryForm'
import { ministryDetail, updateMinistry, getListVolunteers } from 'libs/apis/ministry'
import { useHistory } from 'react-router-dom'
import alert from 'services/alert'
import { cancelConfirmation } from 'libs/utils/confirmation'

function EditMinistry(props) {
	const [ministryName, setMinistryName] = useState()
	const [ministryHead, setMinistryHead] = useState('')
	const [ministryDescription, setMinistryDescription] = useState()
	const [ministryStatus, setMinistryStatus] = useState()
	const [ministryStatusValue, setMinistryStatusValue] = useState()
	const [ministryImageFile, setMinistryImageFile] = useState()
	const [ministryImageAPI, setMinistryImageAPI] = useState()
	const [imagePreview, setImagePreview] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()

	const statuses = [
		{ value: 'Closed', label: 'Closed' },
		{ value: 'Open', label: 'Open' },
	]

	useEffect(() => {
		const fetchData = async () => {
			let res = await ministryDetail(parseInt(props.match.params.id))

			const { ministry_name, image_url, description, status } = res.data.data
			let data = res.data.data.leaders.map((val) => ({
				value: val.user_id,
				label: `${val.fullname}-${val.email}
				`,
			}))

			setMinistryHead(data)
			setMinistryName(ministry_name)
			setMinistryImageAPI(image_url)
			setImagePreview(image_url)
			setMinistryDescription(description)
			setMinistryStatus({
				value: status,
				label: status,
			})
			setMinistryStatusValue(status)
		}

		fetchData()
	}, [props.match.params.id])

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			setIsLoading(true)

			let formdata = new FormData()
			if (ministryHead.length !== 0) {
				formdata.append('ministry_id', props.match.params.id)
				formdata.append('ministry_name', ministryName)
				formdata.append('description', ministryDescription)
				formdata.append('image_file', ministryImageFile ? ministryImageFile : null)
				formdata.append('image_url', ministryImageAPI)
				formdata.append('status', ministryStatusValue)
				let ministryHeadData = ministryHead.map((val) => val.value)
				for (let i = 0; i < ministryHeadData.length; i++) {
					formdata.append(`ministry_head_id[${i}]`, ministryHeadData[i])
				}
			} else {
				alert.failed('Failed', 'Ministry Head Cannot be Empty')
				setIsLoading(false)
			}

			let res = await updateMinistry(formdata)

			if (res.data.api_status) {
				setIsLoading(false)
				alert.succeed('Success', 'Ministry has been updated')
				history.push('/ministry')
			} else {
				alert.failed(`Failed`, 'Cannot update ministry')
				setIsLoading(false)
			}
		} catch (error) {
			console.log(error)
			alert.failed(`Failed`, 'Cannot update ministry')

			setIsLoading(false)
		}
	}

	const handleOnLoadLeaders = async (keyword) => {
		const response = await getListVolunteers(keyword, 0)

		if (response.data.api_status) {
			const options = response.data.data.data.map((user) => ({
				value: user.user_id,
				label: `${user.fullname} - ${user.email}`,
			}))
			return options.filter((i) => i.label.toLowerCase().includes(keyword))
		}
	}

	const handleDescription = (value) => {
		setMinistryDescription(value)
	}

	const handleChangeSelect = (e) => {
		setMinistryStatus({
			value: e.value,
			label: e.value,
		})
		setMinistryStatusValue(e.value)
	}

	const handleMinistryName = (e) => {
		setMinistryName(e.target.value)
	}

	const handleFileChange = (e) => {
		setImagePreview(URL.createObjectURL(e.target.files[0]))
		setMinistryImageFile(e.target.files[0])
	}

	const handleOnLeaderChange = (input) => {
		setMinistryHead(input)
	}

	const handleCancel = async () => {
		const res = await cancelConfirmation()
		if (res.value) {
			history.push('/ministry')
		}
	}

	const church = JSON.parse(localStorage.getItem('church'))
	const name = church.church_name ? church.church_name : '-'
	const breads = [
		{ url: '/ministry', title: 'List Ministry' },
		{ url: '', title: 'Edit Ministry' },
	]

	return (
		<div className="main-content bg-gray-100 pb-24 md:p-6 md:w-5/6">
			<Helmet>
				<title>{name} - Edit Ministry</title>
			</Helmet>
			<PageBreadcrumbs icon={MinistryIcon} title="Edit Ministry" breads={breads} />
			<MinistryForm
				ministryName={ministryName}
				ministryHead={ministryHead}
				ministryImageURL={ministryImageFile}
				imagePreview={imagePreview}
				ministryDescription={ministryDescription}
				ministryStatus={ministryStatus}
				handleMinistryName={handleMinistryName}
				handleOnLeaderChange={handleOnLeaderChange}
				handleOnLoadLeaders={handleOnLoadLeaders}
				handleDescription={handleDescription}
				handleChangeSelect={handleChangeSelect}
				handleFileChange={handleFileChange}
				handleCancel={handleCancel}
				handleSubmit={handleSubmit}
				isLoading={isLoading}
				statuses={statuses}
			/>
		</div>
	)
}

export default EditMinistry
