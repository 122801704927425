import React from 'react'
import photoplaceholder from 'assets/images/placeholder_picture.jpg'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const MinistryDetails = (props) => {
	const { name, description, status, image_url, leaders_name } = props
	return (
		<div className="bg-white shadow-md rounded p-4">
			<h3 className="font-bold text-lg mb-4">{name}</h3>
			<div className="mb-4 w-3/6 h-24 rounded-lg shadow-lg flex justify-center">
				<img className="w-auto rounded h-auto object-cover" src={image_url === 'null' ? photoplaceholder : image_url} alt="ministry_image"></img>
			</div>
			<div className="font-bold mb-2">LEADER DETAIL</div>

			<label className="text-sm font-bold">Ministry Head</label>
			<div className="text-sm mb-4">{leaders_name}</div>

			<hr className="border-2 border-blueSecondary" />

			<div className="font-bold mb-2 mt-3">MINISTRY DETAIL</div>

			<label className="text-sm font-bold">Description</label>

			<ReactQuill modules={{ toolbar: false }} className="text-sm h-24 -ml-4 h-auto" theme={null} value={description ? description : ''} formats={null} />

			<hr className="border-2 border-blueSecondary mt-2" />
			<div className="font-bold mt-3">REQUIREMENT STATUS</div>
			<h1 className="text-primary text-xl">{status}</h1>
		</div>
	)
}

export default MinistryDetails
